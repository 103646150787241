<template>
  <div>
    <section>
      <div>
        <p>Please wait........</p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },

  async created() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        status: this.$route.query.status,
        tx_ref: this.$route.query.tx_ref,
        transaction_id: this.$route.query.transaction_id,
      }),
    };
    const response = await fetch(
      process.env.VUE_APP_ROOT_API + `/service/donation/fwave-transaction`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (data.link) {
          window.location.replace(data.link);
        } else {
          window.location.replace("/");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  },
};
</script>